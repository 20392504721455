import React from 'react';
import { Browser, BrowserWrapper, Container, Content, Footer, FooterLabel, Label, Text } from './style';
import { ReactComponent as RestrictionsImage } from '../../assets/icons/restriction.svg';
import { ReactComponent as GoogleIcon } from '../../assets/icons/Crome.svg';
import { ReactComponent as FireFoxIcon } from '../../assets/icons/Mozilla Firefox.svg';
import { ReactComponent as SafariIcon } from '../../assets/icons/safari-ios-icon.svg';
import { useTranslation } from 'react-i18next';

const BrowserRestrictions = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <RestrictionsImage />
      <Content>
        <Label>{t('Browser is not supported')}</Label>
        <Text>{t('Please use one of the below given browsers')} </Text>
      </Content>
      <Footer>
        <FooterLabel>{t('Supported browsers:')}</FooterLabel>
        <BrowserWrapper gutter={[12, 12]}>
          <Browser span={8}>
            <GoogleIcon />
            <Text>Google Chrome</Text>
          </Browser>
          <Browser span={8}>
            <FireFoxIcon />
            <Text>Mozilla Firefox</Text>
          </Browser>
          <Browser span={8}>
            <div className="safari-parent-class">
              <SafariIcon />
            </div>
            <Text>Safari</Text>
          </Browser>
        </BrowserWrapper>
      </Footer>
    </Container>
  );
};

export default BrowserRestrictions;
